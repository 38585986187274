/* AdCard.css */
.popular-posts .ad-card:hover {
    transform: translateY(-5px);
}

.popular-posts .ad-card a img {
    width: 100%;
    /* 親要素に対して幅を100%にする */
    height: auto;
    /* 高さを固定 */
    object-fit: cover;
    /* 画像をカード内に収める */
    height: 200px;
}

.popular-posts .ad-info {
    padding: 10px;
}

.popular-posts .ad-card .post-info {
    padding-top: 10px;
    font-size: 14px;
}

.popular-posts .ad-card .post-caption {
    font-size: 14px;
    color: #333;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* 最大3行まで表示 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popular-posts .ad-text {
    font-size: 14px;
    color: #333;
}

.popular-hashtags .ad-card {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.popular-hashtags .ad-card a img {
    width: 65%;
    height: auto;
}

.analytics-charts .ad-card {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.analytics-charts .ad-card a img {
    width: 65%;
    height: auto;
}

@media (max-width: 480px) {
    .popular-posts .ad-card a img {
        height: 65px;
    }

    .popular-posts .ad-card .post-caption {
        font-size: 8px;
    }

    .popular-posts .ad-card .post-info {
        padding-top: 10px;
    }

    .analytics-charts .ad-card {
        width: 100%;
        margin-bottom: 0px;
    }

    .popular-hashtags .ad-card a img {
        width: 100%;
    }

    .analytics-charts .ad-card a img {
        width: 100%;
    }
}