.delete-account-section {
    text-align: center;
    margin: 50px auto;
    max-width: 600px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.delete-heading {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.delete-description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
}

.delete-button {
    background-color: #ff6b6b;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.delete-button:hover {
    background-color: #ff4c4c;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
}

.modal-actions button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
}

.modal-actions button:first-child {
    background-color: #e74c3c;
    color: white;
    border: none;
    cursor: pointer;
}

.modal-actions button:last-child {
    background-color: #ccc;
    color: black;
    border: none;
    cursor: pointer;
}

.error-text {
    color: red;
    margin-top: 20px;
}