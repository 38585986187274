.App-header {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;
  flex-direction: column;
}

.App-header h1 {
  font-family: 'Pacifico', cursive;
  font-size: 24px;
  margin: 0;
}

.App-header h1 a {
  color: #fff;
  text-decoration: none;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  z-index: 1100;
}

.menu-toggle div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

.open .bar1 {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  transform: rotate(45deg) translate(-5px, -5px);
}

.App-header nav {
  display: flex;
  gap: 30px;
  margin-top: 10px;
  align-items: center;
}

.App-header nav a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s;
}

.App-header nav a:hover {
  color: #ffd700;
  text-decoration: underline;
}

.App-header nav a.active {
  color: #ffd700;
}

.ad-notice {
  /* 背景色を控えめに変更 */
  color: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 99;
  margin-top: -5px;
  /* ヘッダーと通知の間のスペースを調整 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/* user-info をヘッダーの右端に配置 */
.user-info {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 2vw;
  color: #ffd700;
  /* おしゃれなゴールドカラー */
  background: rgba(0, 0, 0, 0.2);
  /* モダンな半透明背景 */
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* 軽いシャドウで浮いて見える効果 */
  transition: background 0.3s, color 0.3s;
  top: 64px;
}

.user-info:hover {
  background: #ffd700;
  /* ホバー時に背景をゴールドに */
  color: #000;
  /* テキストを黒に */
}

.logout-button {
  background-color: #FFA000;
  /* ボタンの背景色 */
  color: #000;
  /* テキストの色 */
  border: none;
  /* 枠線をなくす */
  padding: 5px 17px;
  /* パディングを追加 */
  border-radius: 5px;
  /* 角を丸くする */
  font-size: 16px;
  /* フォントサイズ */
  cursor: pointer;
  /* カーソルをポインターに変更 */
  transition: background-color 0.3s ease, color 0.3s ease;
  /* ホバー時のトランジション */
}

.logout-button:hover {
  background-color: #FF6700;
  color: #fff;
  /* ホバー時の背景色 */
}

@media (max-width: 1170px) {
  .user-info {
    top: 20px;
  }
}

/* メディアクエリ */
@media (max-width: 850px) {
  .App-header {
    align-items: center;
  }

  .App-header nav {
    margin-top: 20px;
    width: 100%;
    justify-content: space-around;
    gap: 20px;
  }

  .App-header h1 {
    font-size: 22px;
  }

  .App-header nav a {
    font-size: 14px;
  }
}

@media (max-width: 710px) {
  .menu-toggle {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1100;
    margin-top: 8px;
  }

  .App-header nav {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin: 0;
    height: 100vh;
    justify-content: center;
    z-index: 1000;
  }

  .App-header nav.open {
    display: flex;
  }

  .App-header nav a {
    color: white;
    padding: 10px 0;
    width: 100%;
    text-align: center;
  }

  .App-header h1 {
    font-size: 22px;
    text-align: center;
    width: 100%;
  }

  .App-header h1 a {
    display: inline-block;
  }

  .logout-button {
    margin-top: 10px;
  }

  .user-info {
    position: static;
    margin-top: 20px;
  }
}