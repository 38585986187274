/* General styling */
.analytics-charts {
    text-align: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.hourly-analytics-charts {
    margin-top: 25px;
    margin-bottom: 14px;
}

.hourly-analytics-charts div.chart-container {
    margin-bottom: 30px;
}

.hourly-analytics-charts .back-button {
    background-color: #ff6f61;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.hourly-analytics-charts .back-button:hover {
    background-color: #ff4c3b;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

.hourly-analytics-charts .back-button:active {
    background-color: #e6453a;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
}

.analytics-charts .chart-container h2 {
    font-size: 24px;
    color: #262626;
    font-weight: bold;
    padding: 10px;
    margin: 0;
    position: relative;
    display: inline-block;
}

.analytics-charts .chart-container {
    position: relative;
    width: 90%;
    max-width: 800px;
    margin: 0 auto 100px;
    padding: 30px 20px 50px 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.analytics-charts .data-explanation {
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #ff7979;
}

@media (max-width: 768px) {
    .analytics-charts .chart-container {
        width: 95%;
        padding: 20px 10px 40px 10px;
    }

    .analytics-charts .chart-container h2 {
        font-size: 20px;
    }
}

/* DatePicker container styling */
.analytics-charts .date-picker-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 10px;
}

.analytics-charts .date-picker-container label {
    font-size: 16px;
    font-weight: bold;
    color: #262626;
    margin-right: 10px;
    margin-left: 20px;
}

/* DatePicker input styling */
.analytics-charts .react-datepicker-wrapper,
.analytics-charts .react-datepicker__input-container,
.analytics-charts .react-datepicker__input-container input {
    max-width: 90px;
}

.analytics-charts .react-datepicker__input-container input {
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 8px 0px;
    font-size: 16px;
    transition: border-bottom 0.3s ease;
    text-align: center;
}

.analytics-charts .react-datepicker__input-container input:focus {
    border-bottom: 2px solid #ff6f61;
    outline: none;
}

/* Custom DatePicker calendar styling */
.analytics-charts .react-datepicker {
    font-family: 'Arial', sans-serif;
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.analytics-charts .react-datepicker__header {
    background-color: #ff6f61;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
}

.analytics-charts .react-datepicker__current-month,
.analytics-charts .react-datepicker-time__header,
.analytics-charts .react-datepicker-year-header {
    color: #fff;
    font-weight: bold;
}

.analytics-charts .react-datepicker__day-name,
.analytics-charts .react-datepicker__day,
.analytics-charts .react-datepicker__time-name {
    color: #333;
    font-weight: bold;
}

.analytics-charts .react-datepicker__day--selected,
.analytics-charts .react-datepicker__day--keyboard-selected {
    background-color: #ff6f61;
    color: #fff;
    border-radius: 50%;
}

.analytics-charts .react-datepicker__day:hover {
    background-color: #ff4c3b;
    color: #fff;
    border-radius: 50%;
}

.analytics-charts .react-datepicker__day--outside-month {
    color: #ccc;
}

.analytics-charts .no-results-message {
    margin-top: 100px;
}

.analytics-charts .auth-prompt {
    margin-bottom: 150px;
}

@media (max-width: 480px) {
    .analytics-charts .chart-container {
        width: 100%;
        padding: 10px 0px 30px 0px;
        margin-bottom: 50px;
    }

    .date-picker-container {
        margin-bottom: 0px;
    }

    .analytics-charts .chart-container h2 {
        font-size: 14px;
    }

    .analytics-charts .data-explanation {
        text-align: center;
        font-size: 9px;
        padding-left: 0px;
    }

    .analytics-charts canvas {
        height: 400px !important;
    }

    .analytics-charts .error-message {
        margin-bottom: 25px;
    }

    .analytics-charts .date-picker-container {
        flex-direction: column;
    }

    .analytics-charts .date-picker-container label {
        margin: 3px 0px;
    }

    .analytics-charts .react-datepicker-wrapper,
    .analytics-charts .react-datepicker__input-container,
    .analytics-charts .react-datepicker__input-container input {
        width: 100%;
        max-width: 100px;
        padding-top: 0px;
        padding-bottom: 2px;
    }
}

/* Error message styling */
.analytics-charts .error-message {
    color: #ff4c3b;
    font-size: 14px;
    margin-top: 10px;
}