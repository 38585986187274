.App-footer {
    background-color: #333;
    color: white;
    padding: 40px 20px;
    text-align: center;
    margin-top: 20px;
}

.App-footer p {
    margin: 0;
    font-size: 14px;
}

.footer-columns {
    display: flex;
    /* 右揃えにする */
    gap: 120px;
    /* 列間のスペース */
    margin-bottom: 70px;
    margin-left: 125px;
}

.footer-column {
    text-align: right;
}

.footer-column h3 {
    margin-bottom: 20px;
    text-align: left;
}

.footer-column nav {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
}

.footer-column nav a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
}

.footer-column nav a:hover {
    color: #ddd;
}

@media (max-width: 600px) {
    .footer-column nav a {
        font-size: 14px;
    }

    .footer-columns {
        margin-bottom: 50px;
    }

    .footer-columns {
        margin-left: 0px;
        gap: 50px;
    }
}