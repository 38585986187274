.callback-card {
    text-align: center;
    margin: 50px auto;
    padding: 20px;
    max-width: 400px;
}

.callback-card h1 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
}

.callback-card .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ff6b6b;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}