.success-container {
    max-width: 500px;
    width: 90vw;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h2 {
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.success-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.success-button {
    display: block;
    padding: 12px;
    background-color: #e1306c;
    /* インスタのピンク系カラー */
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-button:hover {
    background-color: #c92461;
    transform: translateY(-2px);
}

.success-explain {
    text-align: left;
}