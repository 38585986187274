.no-results-message {
    text-align: center;
    color: #666;
    font-size: 18px;
    margin: 20px 0;
}

@media (max-width: 480px) {
    .no-results-message {
        width: 90vw;
    }
}

@media (max-width: 600px) {
    .no-results-message p {
        text-align: left;
    }
}