/* PopularPosts.css */
.overlay-ad {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-ad-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.overlay-ad-content .ad-card:hover {
  transform: none;
}

.overlay-ad-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  background: #f6f6f6;
}