.posts-wrapper {
  margin-top: 50px;
}

.popular-posts {
  padding: 20px;
  width: 80%;
}

.posts-container-wrapper {
  margin-bottom: 90px;
}

.posts-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 4列に固定 */
  gap: 20px;
  justify-content: center;
}

/* スマホサイズ用のメディアクエリを追加 */
/* @media (max-width: 768px) {
  .popular-posts {
    width: 95%;
  }
} */

@media (max-width: 480px) {
  .popular-posts {
    width: 95%;
  }

  .posts-container {
    grid-template-columns: repeat(2, 1fr);
    /* より小さい画面サイズでは2列に変更 */
  }

  .posts-container-wrapper {
    margin-bottom: 55px;
  }

  .popular-posts .posts-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 3px;
    /* スマホサイズでは3列に変更 */
  }

  .popular-posts .post-card {
    height: 155px;
  }

  .popular-posts .post-caption {
    font-size: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .popular-posts .post-image {
    height: 65px;
  }

  .popular-posts .posts-container-wrapper h2 {
    font-size: 14px;
  }

  .popular-posts .post-likes {
    font-size: 8px;
  }

  .popular-posts .post-timestamp {
    font-size: 8px;
  }
}

.post-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  width: 100%;
  /* 各カードの幅をグリッドアイテムの幅に合わせる */
}

.post-card:hover {
  transform: translateY(-5px);
}

.post-image {
  width: 100%;
  /* 親要素に対して幅を100%にする */
  height: 200px;
  /* 高さを固定 */
  object-fit: cover;
  /* 画像をカード内に収める */
}

.post-info {
  padding: 10px;
}

.post-caption {
  font-size: 14px;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* 最大3行まで表示 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-metrics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.post-likes {
  font-size: 14px;
  color: #e0245e;
  display: flex;
  align-items: center;
}

.post-likes .fa-heart {
  margin-right: 5px;
}

.heart-icon {
  color: #e0245e;
}

.post-timestamp {
  font-size: 12px;
  color: #777;
}

.auth-prompt {
  display: flex;
  gap: 20px;
  /* ボタン間のスペース */
  flex-wrap: wrap;
  /* レスポンシブ対応 */
  justify-content: center;
  /* 中央寄せ */
}

.auth-button {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  /* グラデーション */
  color: white;
  /* テキストの色 */
  border: none;
  /* 枠線をなくす */
  padding: 15px 30px;
  /* パディング */
  border-radius: 30px;
  /* 角を丸くしてカプセル状に */
  font-size: 18px;
  /* フォントサイズ */
  cursor: pointer;
  /* カーソルをポインターに変更 */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  /* ボックスシャドウで立体感を追加 */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* アニメーション */
}

.auth-button:hover {
  transform: translateY(-5px);
  /* ホバー時の浮き上がり効果 */
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  /* ホバー時のシャドウ */
}

.auth-button:active {
  transform: translateY(0px);
  /* クリック時に元の位置に戻す */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  /* クリック時のシャドウ */
}

.popular-posts .no-results-message {
  margin-top: 100px;
}

.popular-posts .auth-prompt {
  margin-bottom: 150px;
}

@media (max-width: 768px) {
  .auth-button {
    width: 60%;
    /* モバイル時はボタンを幅いっぱいに */
    margin-bottom: 10px;
    /* 下のマージンでボタン間隔を調整 */
  }
}