.popular-hashtags {
  text-align: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.popular-hashtags .chart-container {
  position: relative;
  width: 90%;
  max-width: 800px;
  margin: 0 auto 100px;
  padding: 30px 20px 50px 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popular-hashtags .chart-container h3 {
  font-size: 24px;
  color: #262626;
  font-weight: bold;
  padding: 10px;
  margin: 0;
  position: relative;
  display: inline-block;
}

.popular-hashtags .hashtag-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.popular-hashtags .hashtag-item {
  margin: 5px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.popular-hashtags .hashtag-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
}

.popular-hashtags .show-more-button {
  width: 40%;
  height: 45px;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.popular-hashtags .show-more-button:hover {
  background-color: #0056b3;
}

/* メディアクエリでレスポンシブ対応 */
@media (max-width: 768px) {
  .popular-hashtags .chart-container {
    width: 95%;
    padding: 20px 10px 40px 10px;
  }

  .popular-hashtags .chart-container h3 {
    font-size: 20px;
  }

  .popular-hashtags .hashtag-item {
    margin: 5px;
  }

  .popular-hashtags .show-more-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .popular-hashtags .chart-container {
    width: 100%;
    padding: 10px 5px 30px 5px;
    margin-bottom: 50px;
  }

  .popular-hashtags .chart-container h3 {
    font-size: 14px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .popular-hashtags .hashtag-item {
    margin: 3px;
  }

  .popular-hashtags .show-more-button {
    width: 80%;
    font-size: 12px;
    padding: 8px 16px;
  }
}

.mosaic-effect {
  filter: blur(5px);
  /* モザイク風にぼかしを追加 */
  opacity: 0.7;
  /* 少し透明にすることでエフェクトを強調 */
}

.auth-prompt {
  display: flex;
  gap: 20px;
  /* ボタン間のスペース */
  flex-wrap: wrap;
  /* レスポンシブ対応 */
  justify-content: center;
  /* 中央寄せ */
}

.auth-button {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  /* グラデーション */
  color: white;
  /* テキストの色 */
  border: none;
  /* 枠線をなくす */
  padding: 15px 30px;
  /* パディング */
  border-radius: 30px;
  /* 角を丸くしてカプセル状に */
  font-size: 18px;
  /* フォントサイズ */
  cursor: pointer;
  /* カーソルをポインターに変更 */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  /* ボックスシャドウで立体感を追加 */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* アニメーション */
}

.auth-button:hover {
  transform: translateY(-5px);
  /* ホバー時の浮き上がり効果 */
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  /* ホバー時のシャドウ */
}

.auth-button:active {
  transform: translateY(0px);
  /* クリック時に元の位置に戻す */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  /* クリック時のシャドウ */
}

.popular-hashtags .no-results-message {
  margin-top: 100px;
}

.popular-hashtags .auth-prompt {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .auth-button {
    width: 60%;
    /* モバイル時はボタンを幅いっぱいに */
    margin-bottom: 10px;
    /* 下のマージンでボタン間隔を調整 */
  }
}