.goodbye-container {
    text-align: center;
    margin-top: 100px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.goodbye-title {
    font-size: 2em;
    color: #333;
}

.goodbye-message {
    font-size: 1.2em;
    color: #666;
    margin: 20px 0;
}

.home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.home-link:hover {
    background-color: #555;
}