.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.scroll-top {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    color: white;
    transition: background-color 0.3s, transform 0.3s;
}

.scroll-top:hover {
    background-color: #ff5733;
    transform: translateY(-2px);
}

@media (max-width: 600px) {
    .scroll-to-top {
        bottom: 10px;
        right: 10px;
    }

    .scroll-top {
        padding: 12px;
        font-size: 24px;
        /* 小さい画面ではアイコンのサイズも調整 */
        width: 48px;
        height: 48px;
    }
}