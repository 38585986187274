.auth-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h2 {
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.auth-form input {
    width: 95%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.auth-form button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.auth-form button:hover {
    background-color: #555;
}

.social-login {
    margin-top: 20px;
}

.social-login p {
    margin-bottom: 10px;
    color: #666;
    font-size: 14px;
}

/* エラーメッセージのスタイル */
.error-message {
    color: #e74c3c;
    background-color: #f9ebeb;
    padding: 10px;
    border-radius: 4px;
    font-size: 12px;
    text-align: left;
}
