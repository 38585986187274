.socialLogin button.customGoogleButton {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .socialLogin button.customGoogleButton:hover {
    background-color: #f1f1f1; /* ホバー時の背景色 */
    color: #333; /* ホバー時のテキスト色 */
  }
  
  .customGoogleButton .google-icon {
    margin-right: 10px;
  }