.App {
  text-align: center;
}

main {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
}

.posts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}

.post-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.post-card:hover {
  transform: translateY(-5px);
}

.post-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.post-info {
  padding: 10px;
}

.post-caption {
  font-size: 14px;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 最大3行まで表示 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-likes {
  font-size: 14px;
  color: #e0245e;
  display: flex;
  align-items: center;
}

.post-likes .fa-heart {
  margin-right: 5px;
}

.heart-icon {
  color: #e0245e;
}
