.contact-form {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: max-width 0.3s ease;
}

.contact-form h2 {
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.contact-form .form-group {
    margin-bottom: 15px;
}

.contact-form .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
    width: 95%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0px;
}

.contact-form .form-group textarea {
    height: 150px;
}

.contact-form button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #333;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #555;
}

.contact-form .thank-you-message {
    text-align: center;
    font-size: 16px;
    color: #4caf50;
    margin-top: 20px;
    font-weight: bold;
}

/* スマホ向け */
@media (max-width: 768px) {
    .contact-form {
        width: 75vw;
        padding: 15px;
    }

    .contact-form .form-group input,
    .contact-form .form-group textarea {
        padding: 8px;
    }

    .contact-form button {
        padding: 10px;
        font-size: 14px;
    }
}

/* PC向け */
@media (min-width: 1200px) {

    .form-group input,
    .form-group textarea {
        padding: 12px;
        font-size: 16px;
    }

    .contact-form button {
        padding: 12px;
        font-size: 16px;
    }
}

@media (min-width: 769px) {
    .contact-form {
        width: 40vw;
    }
}