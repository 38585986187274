.homepage {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    padding: 20px;
    max-width: 800px;
    margin: 78px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: left;
}

.homepage-header {
    text-align: center;
    margin-bottom: 30px;
}

.homepage-title {
    font-size: 2.5em;
    margin-bottom: 10px;
    background: -webkit-linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.homepage-header p {
    font-size: 1.2em;
    color: #555;
}

.homepage-content {
    line-height: 1.6;
    font-size: 1em;
}

.homepage-content p {
    margin-bottom: 20px;
}

.homepage-content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.homepage-content ul li {
    margin-bottom: 10px;
    font-size: 1em;
    color: #444;
}

.homepage-content strong {
    font-weight: bold;
    font-size: 1.1em;
}

/* 新しいセクション用のスタイル追加 */
.features-section, .categories-section {
    margin-bottom: 40px;
}

.section-title {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

.feature-list {
    list-style-type: disc;
    margin-left: 40px;
    margin-bottom: 20px;
}

.feature-list li {
    font-size: 1em;
    margin-bottom: 10px;
    color: #444;
}

.login-reminder {
    font-size: 0.9em;
    color: #555;
    margin-top: 20px;
    font-style: italic;
}

.categories-section p {
    font-size: 1em;
    line-height: 1.6;
}

/* 追加: CTA（Call To Action）セクションのスタイル */
.cta-section {
    background: linear-gradient(45deg, rgb(240, 148, 51), rgb(230, 104, 60) 25%, rgb(220, 39, 67) 50%, rgb(204, 35, 102) 75%, rgb(188, 24, 136));
    color: white;
    text-align: center;
    padding: 40px 20px;
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.cta-title {
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: bold;
}

.cta-text {
    font-size: 1.2em;
    line-height: 1.6;
}


/* レスポンシブ対応 */
@media (max-width: 600px) {
    .homepage {
        padding: 15px;
        margin: 30px auto;
    }

    .homepage-title {
        font-size: 2em;
    }

    .homepage-header p {
        font-size: 1.2em;
    }

    .homepage-content {
        font-size: 1.0em;
    }

    .homepage-content ul li {
        font-size: 1.0em;
    }

    .section-title {
        font-size: 1.5em;
    }

    .feature-list li, .categories-section p {
        font-size: 0.9em;
    }

    .homepage-content ul {
        margin-left: -10px;
    }

    .cta-title {
        font-size: 1.2em;
    }

    .cta-text {
        font-size: 1.1em;
    }

}